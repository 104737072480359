<template>
  <div>
    <Navbar page="Visualizar diagnóstico aplicado" link="/diagnosticos" nameLink="Diagnósticos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Data
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Diagnóstico
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Destinatário
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Revisar
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Status
                      </th>
                      
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id">
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ formatDate(item.criadoEm) }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ item.diag_base.tema }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ item.destinatarioAgente ? item.destinatarioAgente.nome : '' }}
                            {{ item.destinatarioPessoa ? item.destinatarioPessoa.nome : '' }}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-2">
                          <div class="text-sm flex flex-col space-y-1">
                            <router-link 
                              :to="`/diagnosticos/visualizar/${item.controle._id}/revisar/${item._id}`" 
                              type="button" 
                              class="px-2 py-1 text-center rounded-sm bg-gray-600 text-white"
                            >
                              Revisar
                            </router-link>
                            <button 
                              @click="copyToClipboard(`diagnosticos/answer/${item._id}`)" 
                              type="button" 
                              class="px-2 py-1 rounded-sm bg-blue-600 text-white">
                              Copiar Link para responder
                            </button>
                          </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ item.status }}
                          </div>
                        </div>
                      </td>
                      <td class="flex flex-wrap px-6 py-4 text-sm font-medium gap-0.5 items-center">
                          
                          <button
                            v-if="!$store.state.user.cliente && !arquivados"
                            @click="remove(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1 ">
                            Arquivar
                          </button>
                          <button
                            v-else-if="!$store.state.user.cliente && arquivados"
                            @click="ativar(item._id)" 
                            type="button" 
                            class="text-white bg-green-600 hover:bg-green-700 rounded py-1 px-4 mx-1 ">
                            Ativar
                          </button>
                          <button
                            v-if="arquivados"
                            @click="removeArquivada(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1 ">
                            Excluir
                          </button>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      route: '/diagnosticos/visualizar',
      arquivados: false,
      list: [],
      selecionados: [],
      page: 1,
      perPage: 50,
      total: 0,
      query: { page: 1, limit: 50, skip: 0  },
    }
  },
  methods: {
    copyToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = `${window.location.origin}/${text}`;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        this.$vToastify.success('Envie para o responsável responder o diagnóstico', "Link copiado");
      } catch (err) {
        this.$vToastify.error('Falha ao copiar o texto. Por favor, copie manualmente.');
      }
      document.body.removeChild(textarea);
    },

    formatDate(data) {
      return `${moment(data).format("DD/MM/YYYY HH:mm:ss")}`;
    },

    async start(id) {
      await this.
      $http
      .get(`/v1/diagnosticoAplicado/diagnostico/${id}?skip=${this.query.skip}&page=${this.query.page}&limit=${this.query.limit}`)
      
      .then((resp)=>{
        this.list = resp.data.itens;
        this.total = resp.data.total;
      })
    },

    async removeArquivada (id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja excluir esse diagnóstico arquivado?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/diagnosticosBase/remover/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start(this.$route.params.id);
            }
          }
      });
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar esse diagnóstico?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/diagnosticoAplicado/${id}`)
              .then(()=>{
                this.$vToastify.success("Arquivado com sucesso!");
                this.page = 1;
                this.start(this.$route.params.id);
              }).catch(()=> this.$vToastify.success("Diagnóstico não arquivado!"))
              
            }
          }
      });
    },

    async ativar(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja ativar esse diagnóstico?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.put(`/v1/diagnosticosBase/`, { _id: id, ativo: true })
              .then((resp)=>{
                this.$vToastify.success(resp.data.msg);
                this.page = 1;
                this.start();
              })
              .catch(()=> this.$vToastify.error('Erro ao ativar item.'))
            }
          }
      });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.arquivados = this.arquivados;
      this.start(this.$route.params.id);
    }
  },
  async beforeMount() {
    this.start(this.$route.params.id);
  },
}
</script>